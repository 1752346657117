//Vendors
//import 'babel-polyfill';
//import 'classlist-polyfill';
import FastClick from 'fastclick';

//Dependencies
//import './vendors/init-sw'; //Service Worker

//Modules
import Header from './components/header.comp';
import Project from './components/project.comp';
import Studio from './components/studio.comp';
import Hero from './components/hero.comp';

//On DOMReady, start the app
const DOMReady = function() {
    return function() {
        if (document.readyState === 'interactive') {
            //Fastclick initiate
            FastClick(document.body);

            //Init modules
        }
        if (document.readyState === 'complete') {
            Header.init();
            Project.init();
            Hero.init();
            //If images needed for modules, init the module here
            Studio.init();

            var _slides = [].slice.call(document.querySelectorAll(".home-bg"));
            var _sliderIndex = 0;

            var sliderHome = function() {

                _sliderIndex++;
                if (_sliderIndex === (_slides.length)) {
                    _sliderIndex = 0;
                }

                var slide = _slides[_sliderIndex];
                for (var i = 0; i < _slides.length; i++) {
                    if (i === _sliderIndex) {
                        _slides[i].classList.add('show-image');
                    } else {
                        _slides[i].classList.remove('show-image');
                    }
                }
            };

            var sliderTimeout = setInterval(function() {
                sliderHome();
            }, 3000);
            sliderHome();

            document.getElementById('newsletter-form').addEventListener("submit", function(e) {

                e.preventDefault();

                let emailInput = document.getElementById('email').value;

                function validateEmail(email) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                }

                if (!validateEmail(emailInput)) {
                    document.getElementById('email-input__error').classList.add('show');
                    return;
                }

                let _postData = {
                    email: emailInput
                };

                document.getElementById('loader-top').classList.add('show');

                $.ajax({
                    method: 'POST',
                    data: _postData,
                    dataType : "json",
                    url: templateUrl + '/api/mailchimp.php'
                }).done(function( data ) {

                    let callback = document.getElementById('form-callback');

                    if (data.success) {
                        document.getElementById('email').value = "Thanks for your registration";
                    }
                    document.getElementById('loader-top').classList.remove('show');
                    //callback.classList.add('show');
                });

            });

            document.body.addEventListener('keyup', function(e) {
                e.preventDefault();
                if (e.keyCode === 27) {
                    document.querySelector('.one-project__close').click();
                }
            });

        }
    };
};
document.onreadystatechange = DOMReady();

//Signature 9P
console.log('\n%cmade by neuvièmepage', 'background: linear-gradient(-39deg,#bd2db3  5%,#c03189  35%,#c02f51 65%,#c02f51  95%); color: #fff; padding:5px 10px;');
console.log('%cwww.neuviemepage.com\n', 'margin-left:4px;text-decoration:underline; ');