
const Studio = ( _ => {

    const slides = document.querySelectorAll('.location__content__bgs>div');
    let index = 0;
    let interval;
    const intervalDelay = 4000;

    return {

        init: function(){
            interval = setInterval( this.slide, intervalDelay );
        },

        slide: function(){
            requestAnimationFrame( _ => {
                slides[index].classList.remove('show');
                index++;
                if(index >= slides.length)
                    index = 0;
                slides[index].classList.add('show');
            });
        }

    };

})();

export default Studio;
