
const Project = ( _ => {

    const projects = document.querySelectorAll('.project');
    const oneProject = document.querySelector('.one-project');
    const close = oneProject.querySelector('.one-project__close');
    const iframe = oneProject.querySelector('iframe');
    const link = oneProject.querySelector('.one-project__link');

    return {

        init: function(){
            this.bindEvents();
        },

        bindEvents: function(){
            for(let i=0; i<projects.length; i++){
                projects[i].addEventListener('click', this.open, false);
            }
            close.addEventListener('click', this.close, false);
        },

        open: function(){
            let href = this.getAttribute('data-imdb-link');
            let vimeoId = this.getAttribute('data-vimeo-id');
            let src = `//player.vimeo.com/video/${vimeoId}?autopause=1&autoplay=0&badge=1&loop=0&byline=1&portrait=0&title=1`;
            requestAnimationFrame( _ => {
                oneProject.classList.add('show');
                link.href = href;
                setTimeout( _ => {
                    iframe.src = src;
                }, 225);
            });
        },

        close: function(){
            requestAnimationFrame( _ => {
                oneProject.classList.remove('show');
                iframe.src = '';
                link.href = '';
            });
        }

    };

})();

export default Project;
