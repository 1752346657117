
const Header = ( _ => {

    const header = document.querySelector('.header');
    const blackLogo = header.querySelector('.black');
    let blackLogoHeight = 0;
    let blackLogoMarginTop = 0;
    const blackLogoInner = blackLogo.querySelector('div');
    const whiteBlock = document.querySelector('.about');
    let whiteBlockTop = 0;
    let whiteBlockBottom = 0;
    let aboutHeight = document.querySelector('.about').clientHeight;

    const langs = document.querySelector('.langs');

    let posY = window.pageYOffset || document.documentElement.scrollTop;

    const throttle = ( _ => {
        let timer;
        return function(fn, delay){
            if (!timer) {
                timer = setTimeout( _ => {
                    requestAnimationFrame( _ => {
                        fn();
                        clearTimeout(timer);
                        timer = undefined;
                    })
                }, delay);
            }
        }
    })();

    return {

        init: function(){

            this.bindEvents();
            this.updateValues();
            this.render();

        },

        bindEvents: function(){

            window.addEventListener('scroll', _ => {
                //throttle( _ => {
                    this.onScroll();
                    this.render();
                //}, 300);
            }, false);

            window.addEventListener('resize', _ => {
                throttle( _ => {
                    this.resize();
                }, 300);
            }, false);

        },

        onScroll: function() {

            posY = window.pageYOffset || document.documentElement.scrollTop;

        },

        updateValues: function(){

            whiteBlockTop = whiteBlock.offsetTop;
            whiteBlockBottom = whiteBlockTop + whiteBlock.offsetHeight;
            blackLogoHeight = blackLogo.offsetHeight;
            blackLogoMarginTop = window.innerWidth * 2 / 100; //Margin top 2%;
            this.onScroll();

        },

        resize: function(){

            this.updateValues();
            this.onScroll();
            this.render();
            aboutHeight = document.querySelector('.about').clientHeight;

        },

        render: function(){

            requestAnimationFrame( _ => {

                if( (posY > whiteBlockTop) && (posY <= (whiteBlockTop + aboutHeight)) )
                {
                    //Show Header Logo
                    header.classList.add('show');
                    let difference = posY - whiteBlockBottom + blackLogoHeight + blackLogoMarginTop;
                    if(difference < 0){
                        difference = 0;
                    } else if(difference > blackLogoHeight){
                        difference = blackLogoHeight;
                    }
                    langs.classList.add('black');
                    blackLogo.style['transform'] = `translateY(${-difference}px)`;
                    blackLogo.style['webkitTransform'] = `translateY(${-difference}px)`;
                    blackLogoInner.style['transform'] = `translateY(${difference}px)`;
                    blackLogoInner.style['webkitTransform'] = `translateY(${difference}px)`;
                }
                else
                {
                    langs.classList.remove('black');
                    header.classList.remove('show');
                }

            });

        }

    };

})();

export default Header;
